<template>
  <transition name="dropdown-fade">
    <div
      v-show="!isShowing"
      :class="[
        isNetworkActive
          ? 'bg-green-500/20 text-green-500 dark:bg-green-500/5'
          : 'bg-red-500/20 text-red-500 dark:bg-red-500/5',
      ]"
      class="sticky top-0 flex w-full items-center justify-center py-4 text-center text-xs font-bold tracking-wide shadow-custom backdrop-blur-sm"
    >
      {{
        isNetworkActive
          ? "You're back online"
          : "You're offline, check your internet connection"
      }}
    </div>
  </transition>
</template>

<script setup lang="ts">
import { Network } from '@capacitor/network'
import { Capacitor } from '@capacitor/core'

// Reusable composable
const { isOnline } = useNetwork()

// For web
const isShowing = ref(true)
watch(
  isOnline,
  (val) => {
    if (val) {
      setTimeout(() => {
        isShowing.value = true
      }, 1000)
    } else {
      isShowing.value = false
    }
  },
  { immediate: true },
)

// Capacitor
const isConnected = ref(false)
Network.addListener('networkStatusChange', (status) => {
  isConnected.value = status.connected
  if (isConnected.value) {
    setTimeout(() => {
      isShowing.value = true
    }, 1000)
  } else {
    isShowing.value = false
  }
})

const isNetworkActive = computed(() => {
  if (Capacitor.getPlatform() !== 'web') {
    return isConnected.value
  } else {
    return isOnline.value
  }
})
</script>
